<template>
  <div>
    <AppBar />

    <v-row
      justify="center"
      no-gutters>
      <h1 class="offBlack--text text-uppercase text-center">Forgotten your password?</h1>
    </v-row>

    <v-form
      @submit.prevent="sendEmail"
      class="px-6">
      <v-row no-gutters>
        <v-text-field
          color="primaryColor"
          class="my-4 px-4"
          type="email"
          v-model="email">
          <template slot="label"> Enter your <span class="font-weight-bold">work email address</span> </template>
        </v-text-field>
      </v-row>

      <v-row
        justify="center"
        no-gutters>
        <v-col cols="10">
          <v-btn
            type="submit"
            class="brand-gradient"
            :loading="isSendingPasswordResetEmail"
            :disabled="isSendingPasswordResetEmail"
            dark
            rounded
            block
            large
            depressed
            >Send reset email</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <div
      class="reset-password-email-success mt-10 px-6"
      v-if="isEmailSentSuccessfully">
      Password reset email sent. Please check your email and click the link.
    </div>

    <div
      class="reset-password-email-error mt-10 px-6"
      v-if="isEmailNotSent">
      No account found with that email address. Something wrong? Contact app ondemand@weareluminate.co
      <!-- for future to put backend messages, for now I'll stick with the message from figma -->
      <span v-if="false">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
  import ForgotPasswordMixin from '@/views/auth/forgot-password/ForgotPassword.mixin.vue';
  export default {
    mixins: [ForgotPasswordMixin],
  };
</script>

<style lang="css" scoped>
  .forgot-password-title {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.16em;
    text-align: center;
  }
  .reset-password-email-success,
  .reset-password-email-error {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
  .reset-password-email-success {
    background: linear-gradient(to right, var(--v-primaryColor-base), var(--v-secondaryColor-base));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .reset-password-email-error {
    color: var(--v-errorDark-base);
  }
  h1 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.16em;
  }
  .theme--light.v-label {
    color: var(--v-brandGrey2-base) !important;
  }
</style>
